import React, {useContext, useEffect, useState} from "react";
import {Button, Dropdown, Header, Icon, Input, Loader, Modal} from "semantic-ui-react";
import {useTranslation} from "react-i18next"
import axios from "axios";
import {AuthContext, downloadCSV, toastError} from "../../services";

const ExportDataByPatientIds = ({ afterSave, closeDropDown }) => {
    const auth = useContext(AuthContext);
    const {t} = useTranslation()
    const [patientIds, setPatientIds] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    /* eslint-disable */
    useEffect(() => {
        if(modalOpen === true){
            closeDropDown(true);
        } else {
            closeDropDown(false);
        }
    }, [modalOpen]);
    /* eslint-enable */

    const handleSave = () => {
        setIsLoading(true)
        axios.get(
            `${process.env.REACT_APP_SERVER_URI}/api/insurance-export-selected-patients?patientIds=${patientIds?.trim()}`,
            {headers: {Authorization: "Bearer " + auth.keycloak.token}})
            .then(function(response) {
                if (response.status === 200) {
                    downloadCSV(JSON.stringify(response?.data, null, "\t"), `Data.json`, "application/json;charset=utf-8;")
                    setModalOpen(false)
                    afterSave()
                }
                else {
                    toastError("Export fehlgeschlagen: " + response.statusText);
                }
            })
            .catch(function(error) {
                toastError("Exportfehler: ", error);
            })
            .finally(() => {
                setIsLoading(false)
                setPatientIds("")
            })
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setPatientIds("")
        setModalOpen(false)
    }

    return (
            <Modal open={modalOpen}
                   onClose={handleClose}
                   size={"tiny"}
                   onOpen={handleOpen}
                   closeOnDimmerClick={false}
                   closeOnEscape={false}
                   trigger={<Dropdown.Item icon="arrow circle down" text={t("data_export_by_patient_ids")}/>}>

                <Header>
                    <Icon name={"arrow circle down"} inverted /> {t("data_export_by_patient_ids")}
                </Header>

                <Modal.Content>
                    <Modal.Description>
                        <label>{t("write_down_patient_ids")}</label>
                        <Input
                            fluid={true}
                            value={patientIds}
                            onChange={(event) => setPatientIds(event?.target?.value)}
                            className={"full-width"}
                        />
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button basic onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button color={"teal"} onClick={handleSave} disabled={!patientIds?.trim()}>
                        {isLoading ?
                            <>
                                <Loader size="tiny" active inline style={{ marginRight: "5px" }} />{`${t("exporting_txt")}...`}
                            </> : t("export_txt")}
                    </Button>
                </Modal.Actions>
            </Modal>
    );
};

export default ExportDataByPatientIds;